<template>
  <div class="animated fadeIn">
    <services-table
      :label="'سرویس‌های در منزل'"
      :get="'/clinic/services/allOutServices'"
      :new="'/admin/newHomeService/'"
      :edit="'/admin/editHomeService/'"
      :fields="Fields"
      :excelFields="excelFields"
    />
  </div>
</template>
<script>
const ServicesTable = () => import("@/components/services/ServicesTable");

export default {
  components: { ServicesTable },
  data() {
    return {
      Fields: [
        { key: "index", label: "#" },
        { key: "name", label: "عنوان" },
        { key: "cost", label: "قیمت (ریال)" },
        { key: "status", label: "وضعیت" },
        { key: "operation", label: "جزییات " },
      ],
      excelFields: [
        { field: "name", label: "عنوان" },
        { field: "cost", label: "قیمت (ریال)" },
        { field: "status", label: "وضعیت" },
      ],
    };
  },
};
</script>

